import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import { BridgeTokenRaw } from '@/store/modules/bridge/models/bridge-token-raw';

export const BRIDGE_TOKENS: BridgeTokenRaw[] = [
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1: 'lovelace',
    tokenAddress2: '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1: 'e043fd7b2076ea9e1b279d200b59e153bf6b299a72ce6e2c14aeb790.BLUES',
    tokenAddress2: '0x8c008BBA2Dd56b99f4A6aB276bE3a478cB075F0C',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1: '804f5544c1962a40546827cab750a88404dc7108c0f588b72964754f.VYFI',
    tokenAddress2: '0x55fD7731665F4f4CbE9894b3b6982FBA8a12AF45',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1: '5dac8536653edc12f6f5e1045d8164b9f59998d3bdc300fc92843489.NMKR',
    tokenAddress2: '0x46f07469c0163d509F22443a6aa4eb6536A74F58',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1:
      'b6a7467ea1deb012808ef4e87b5ff371e85f7142d7b356a40d9b42a0.Cornucopias [via ChainPort.io]',
    tokenAddress2: '0x1af394Cd3568D162406668317020A8c39Cc3fF5D',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.CARDANO,
    blockchain2: BLOCKCHAIN_NAME.MILKOMEDA,
    tokenAddress1: 'db825a73091c1bf24cc0f01741f617e8a984a53e84deb77b4728e19c.CRDIDX',
    tokenAddress2: '0x619AC8Cf6A8e6b02c118B1B1A762Fd2b18718f04',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    blockchain2: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    tokenAddress1: 'lovelace',
    tokenAddress2: '0x1a40217B16E7329E27FDC9cED672e1F264e07Cc2',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    blockchain2: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    tokenAddress1: '951038c684bf4b1e6c8a8cc549e65a1e6f7ec9ea44940791a69f7367.m3s6',
    tokenAddress2: '0x5fA38625dbd065B3e336e7ef627B06a8e6090e8F',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    blockchain2: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    tokenAddress1: 'cc53696f7d40c96f2bca9e2e8fe31905d8207c4106f326f417ec3672.tReserveCoin',
    tokenAddress2: '0x66c34c454f8089820c44e0785ee9635c425c9128',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    blockchain2: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    tokenAddress1: '0a05bb0e22d3a6ef1ff3de455ee181d1e8a4b531fd4f1dce88603bd7.m3s3',
    tokenAddress2: '0xa7c99d0b52013432bf75ca925173999d28a6cae5',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
  {
    blockchain1: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    blockchain2: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    tokenAddress1: '27f2e501c0fa1f9b7b79ae0f7faeb5ecbe4897d984406602a1afd8a8.tStableCoin',
    tokenAddress2: '0xcba90fb1003b9d1bc6a2b66257d2585011b004e9',
    blockchain1Fee: '0.1',
    blockchain2Fee: '1',
  },
];
