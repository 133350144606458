import { BridgeEventCommonPayload } from '@/store/modules/bridge/models/bridge-event';
import {
  INotification,
  INotificationStep,
} from '@/store/modules/notifications/models/notification.interface';
import { format } from '@/utils/format';
import { useI18n } from 'vue-i18n';

export function useBridgeNotificationOptions() {
  const { t, te } = useI18n();

  const getSwapDetails = (bridgeEventPayload: BridgeEventCommonPayload) =>
    `${t('bridging.bridging')} ${format(bridgeEventPayload.from.amount)} ${
      bridgeEventPayload.from.token.unwrapWETH().symbol
    } -> ${format(bridgeEventPayload.to.amount)} ${
      bridgeEventPayload.to.token.unwrapWETH().symbol
    } `;

  function getBridgeInProgressNotificationOptions(
    bridgeEventPayload: BridgeEventCommonPayload,
    options: {
      id: string;
      step: INotificationStep;
      explorerLink?: string;
    },
  ): INotification {
    const currentStep = options.step.current;
    const subheader = t(`bridging.notification.step${currentStep}.title`, {
      fromBlockchain: bridgeEventPayload.from.blockchain.label,
      toBlockchain: bridgeEventPayload.to.blockchain.label,
    });
    const stepInfo = t(`bridging.notification.step${currentStep}.info`);

    return {
      ...options,
      status: 'inProgress',
      subheader,
      content: [
        `${getSwapDetails(bridgeEventPayload)} ${stepInfo}`,
        `${t('bridging.notification.estimatedTimeArrival')}`,
      ],
    };
  }

  function getBridgeSuccessNotificationOptions(
    bridgeEventPayload: BridgeEventCommonPayload,
    options: {
      id: string;
      step: INotificationStep;
      explorerLink?: string;
    },
  ): INotification {
    const currentStep = options.step.current;
    const success = t(`bridging.notification.step${currentStep}.success`, {
      fromBlockchain: bridgeEventPayload.from.blockchain.label,
      toBlockchain: bridgeEventPayload.to.blockchain.label,
    });
    const subheader = t(`bridging.notification.step${currentStep}.title`, {
      fromBlockchain: bridgeEventPayload.from.blockchain.label,
      toBlockchain: bridgeEventPayload.to.blockchain.label,
    });
    const successPrependKey = `bridging.notification.step${currentStep}.successPrepend`;
    const successPrepend = te(successPrependKey) ? t(successPrependKey) : '';

    return {
      ...options,
      status: 'success',
      subheader,
      content: [successPrepend, `${getSwapDetails(bridgeEventPayload)} ${success}`],
    };
  }

  function getBridgeErrorNotificationOptions(
    bridgeEventPayload: BridgeEventCommonPayload,
    options: {
      id: string;
      step: INotificationStep;
    },
  ): INotification {
    return {
      ...options,
      status: 'error',
      subheader: undefined,
      content: [
        t('bridging.notification.sorry'),
        `${getSwapDetails(bridgeEventPayload)} ${t('bridging.notification.hasBeenFailed')}`,
      ],
    };
  }

  return {
    getBridgeInProgressNotificationOptions,
    getBridgeSuccessNotificationOptions,
    getBridgeErrorNotificationOptions,
  };
}
