import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import {
  parseBridgeErrors,
  removeBridgeError,
} from '@/store/modules/bridge/helpers/bridge-error-handler.helper';
import { ERROR_MESSAGES } from '@/store/modules/bridge/constants/ERROR_MESSAGES';
import { TRANSLATION_KEYS } from '@/store/modules/bridge/constants/ERROR_TRANSLATION_KEYS';
import {
  isCardanoMainnetOrTestnet,
  isMilkomedaMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { getNativeBlockchainToken } from '@/store/modules/bridge/constants/BLOCKCHAINS';
import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';

type CardanoWalletStore = ReturnType<typeof useCardanoWallet>;

export function useBridgeErrors() {
  function getTokenFromSymbol(bridgeForm: BridgeForm) {
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return getNativeBlockchainToken(BLOCKCHAIN_NAME.CARDANO);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return getNativeBlockchainToken(BLOCKCHAIN_NAME.MILKOMEDA);
    }

    throw Error(`Can not get native token for ${bridgeForm.fromBlockchain.name} chain`);
  }

  function setBalanceExceeded(bridgeForm: BridgeForm, isBalanceExceeded: boolean): void {
    bridgeForm.errors = isBalanceExceeded
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_EXCEEDED, bridgeForm.errors)
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_EXCEEDED, bridgeForm.errors);
  }

  function setAmountTooLow(bridgeForm: BridgeForm, isAmountTooLow: boolean, minAmount: string) {
    bridgeForm.errors = isAmountTooLow
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_TOO_LOW, bridgeForm.errors, {
          minAmount,
          tokenSymbol: bridgeForm.fromToken.unwrapWETH().symbol,
        })
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_TOO_LOW, bridgeForm.errors);
  }

  function setAmountTooHigh(bridgeForm: BridgeForm, isAmountTooHigh: boolean, maxAmount: string) {
    bridgeForm.errors = isAmountTooHigh
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_TOO_HIGH, bridgeForm.errors, {
          maxAmount,
          tokenSymbol: bridgeForm.fromToken.unwrapWETH().symbol,
        })
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_TOO_HIGH, bridgeForm.errors);
  }

  function setIsEnoughAda(bridgeForm: BridgeForm, isEnoughAda: boolean, amount: string) {
    const tokenSymbol = getTokenFromSymbol(bridgeForm);

    bridgeForm.errors = !isEnoughAda
      ? parseBridgeErrors(ERROR_MESSAGES.ADA_FOR_TRANSACTION_TOO_LOW, bridgeForm.errors, {
          amount,
          tokenSymbol,
          network: bridgeForm.fromBlockchain.label,
        })
      : removeBridgeError(TRANSLATION_KEYS.ADA_FOR_TRANSACTION_TOO_LOW, bridgeForm.errors);
  }

  function setWrongCardanoMode(
    bridgeForm: BridgeForm,
    cardanoWallet: CardanoWalletStore,
    isWrongCardanoNet: boolean | null,
  ) {
    const supportedNetworkMode = process.env.VUE_APP_SUPPORTED_NETWORK_MODE;
    isWrongCardanoNet = isWrongCardanoNet && cardanoWallet.isInjected;

    if (isWrongCardanoNet && supportedNetworkMode === 'development') {
      bridgeForm.errors = parseBridgeErrors(
        ERROR_MESSAGES.WRONG_CARDANO_WALLET_DEV_MODE,
        bridgeForm.errors,
      );
    } else if (isWrongCardanoNet && supportedNetworkMode === 'production') {
      bridgeForm.errors = parseBridgeErrors(
        ERROR_MESSAGES.WRONG_CARDANO_WALLET_PROD_MODE,
        bridgeForm.errors,
      );
    } else {
      bridgeForm.errors = removeBridgeError(
        TRANSLATION_KEYS.WRONG_CARDANO_WALLET_DEV_MODE,
        bridgeForm.errors,
      );
      bridgeForm.errors = removeBridgeError(
        TRANSLATION_KEYS.WRONG_CARDANO_WALLET_PROD_MODE,
        bridgeForm.errors,
      );
    }
  }

  return {
    setBalanceExceeded,
    setAmountTooLow,
    setAmountTooHigh,
    setIsEnoughAda,
    setWrongCardanoMode,
  };
}
