import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { isZeroAddress, toWei } from '@/sdk/utils';
import { ERROR_MESSAGES } from '@/store/modules/bridge/constants/ERROR_MESSAGES';
import {
  getBridgeBlockchainName,
  isCardanoMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { BridgeName, BridgeResponse } from 'crypto-sdk/lib/bridge';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import { CardanoBridgeAsset, MilkomedaBridgeAsset } from '@/helpers/bridge-methods';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { BRIDGE_FEE_MILKOMEDA_IN_ADA } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';

export function useBridgeTransactions() {
  const cardanoWallet = useCardanoWallet();

  async function bridgeFromCardano(
    bridgeForm: BridgeForm,
    milkomedaAddress: string,
    isDemo = false,
  ): Promise<BridgeResponse> {
    if (!cardanoWallet.wallet) {
      throw new Error(ERROR_MESSAGES.NO_CARDANO_WALLET);
    }

    if (!isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      throw new Error(ERROR_MESSAGES.WRONG_CARDANO_WALLET_NET);
    }

    if (isZeroAddress(milkomedaAddress)) {
      throw new Error(ERROR_MESSAGES.ZERO_MILKOMEDA_ADDRESS);
    }

    const token = bridgeForm.fromToken;
    const quantity = toWei(bridgeForm.fromAmount, bridgeForm.fromToken.decimals).toFixed(0);

    let cardanoAsset: CardanoBridgeAsset;
    if (token.unwrapWETH().isETHToken()) {
      cardanoAsset = {
        gas: quantity,
      };
    } else {
      cardanoAsset = {
        token: {
          token: token.address,
          quantity,
        },
      };
    }

    const to = {
      chain: getBridgeBlockchainName(bridgeForm.toBlockchain.name),
      address: milkomedaAddress,
    };

    const by = BridgeName.Milkomeda;

    const options = {
      isDemo,
      ttl: 3600,
    };

    console.groupCollapsed('[BRIDGE:FROM_CARDANO] Call `bridge` : ');
    console.log('params: ', {
      cardanoAsset,
      to,
      by,
      options,
    });
    console.groupEnd();

    return cardanoWallet.wallet.bridgeExtra(cardanoAsset, to, by, options);
  }

  async function bridgeFromMilkomeda(
    bridgeForm: BridgeForm,
    milkomedaAddress: string,
  ): Promise<BridgeResponse> {
    if (!cardanoWallet.bridgeProvider) {
      throw new Error('Cardano bridge provider must be initialized before bridge.');
    }

    const token = bridgeForm.fromToken;

    let milkomedaAsset: MilkomedaBridgeAsset;
    if (token.unwrapWETH().isETHToken()) {
      milkomedaAsset = {
        // NOTE:
        // we minus BRIDGE_FEE_MILKOMEDA_IN_ADA because `bridgeFromEVM` will add BRIDGE_FEE_MILKOMEDA_IN_ADA auto.
        gas: toWei(
          bridgeForm.fromAmount.minus(BRIDGE_FEE_MILKOMEDA_IN_ADA),
          bridgeForm.fromToken.decimals,
        ).toString(),
      };
    } else {
      milkomedaAsset = {
        token: {
          token: token.address,
          quantity: toWei(bridgeForm.fromAmount, bridgeForm.fromToken.decimals).toString(),
        },
      };
    }

    const from = {
      chain: getBridgeBlockchainName(bridgeForm.fromBlockchain.name),
      address: milkomedaAddress,
    };

    const to = {
      chain: getBridgeBlockchainName(bridgeForm.toBlockchain.name),
      address: cardanoWallet.address,
    };

    const signer = getInstance().web3.getSigner();

    console.groupCollapsed('[BRIDGE:FROM_MILKOMEDA] Call `bridgeFromEVM` : ');
    console.log('bridgeFromEVM:params: ', {
      milkomedaAsset,
      from,
      to,
      signer,
    });
    console.groupEnd();

    return cardanoWallet.bridgeProvider.bridgeFromEVMExtra(milkomedaAsset, from, to, signer);
  }

  return {
    bridgeFromCardano,
    bridgeFromMilkomeda,
  };
}
