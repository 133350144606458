import BigNumber from 'bignumber.js';
import { format } from '@/utils/format';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import {
  isCardanoMainnetOrTestnet,
  isMilkomedaMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import {
  MILKOMEDA_APPROVE_FEE,
  MIN_ADA_BRIDGE,
} from '@/store/modules/bridge/constants/AMOUNT_LIMITS_FOR_BRIDGE';
import {
  BRIDGE_FEE_MILKOMEDA_IN_ADA,
  MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA,
  TX_FEE_MILKOMEDA_IN_ADA,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { useCardanoBridge } from '@/store/modules/bridge/useCardanoBridge';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { useBridgeCalculations } from './useBridgeCalculations';
import { useBridgeErrors } from './useBridgeErrors';

export function useBridgeValidator() {
  const {
    calculateMinGasBalanceInCardano,
    calculateCardanoMaxAmount,
    calculateMilkomedaMaxAmount,
  } = useBridgeCalculations();

  const {
    setBalanceExceeded,
    setAmountTooLow,
    setAmountTooHigh,
    setIsEnoughAda,
    setWrongCardanoMode,
  } = useBridgeErrors();

  function getFromTokenBlockchainFee(bridgeForm: BridgeForm): {
    blockchainFee: BigNumber;
  } {
    const blockchainFee =
      bridgeForm.fromBlockchain.name === bridgeForm.bridgeToken.blockchain1.name
        ? bridgeForm.bridgeToken.blockchain1Fee
        : bridgeForm.bridgeToken.blockchain2Fee;

    return {
      blockchainFee,
    };
  }

  async function validateFromCardano() {
    console.groupCollapsed('validation [ Cardano -> Milkomeda ]');

    const { bridgeForm, getGasToken } = useCardanoBridge();
    const cardanoWallet = useCardanoWallet();
    const { tokenBalanceRelative } = useCardanoTokens();

    // validate enough ada
    const minADABalanceInCardano = calculateMinGasBalanceInCardano();
    const gasTokenBalance = tokenBalanceRelative(getGasToken().address).value;
    console.log('ADA address : ', getGasToken().address);
    console.log('ADA balance in Cardano : ', getGasToken().address);
    console.log('min ADA in Cardano : ', minADABalanceInCardano);

    setIsEnoughAda(
      bridgeForm as BridgeForm,
      gasTokenBalance.gte(minADABalanceInCardano),
      minADABalanceInCardano.toString(),
    );

    setWrongCardanoMode(bridgeForm as BridgeForm, cardanoWallet, !cardanoWallet.isNetworkSupported);

    if (bridgeForm.fromAmount.lte(0)) {
      console.groupEnd();

      return;
    }

    // validate balance exceed
    const fromBalanceRelative = tokenBalanceRelative(bridgeForm.fromToken.address).value;
    setBalanceExceeded(bridgeForm as BridgeForm, bridgeForm.fromAmount.gt(fromBalanceRelative));

    const minADAAmountForBridge = getFromTokenBlockchainFee(
      bridgeForm as BridgeForm,
    ).blockchainFee.plus(MIN_ADA_BRIDGE);

    // validate amount to low
    if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
      setAmountTooLow(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.isLessThan(minADAAmountForBridge),
        minADAAmountForBridge.toFixed(),
      );
    } else {
      const minNonADAAmount = 1 / 10 ** bridgeForm.fromToken.decimals;
      setAmountTooLow(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.isLessThan(minNonADAAmount),
        minNonADAAmount.toFixed(),
      );
    }

    // validate amount to high
    if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
      const bigADAMaxAmount = await calculateCardanoMaxAmount(
        bridgeForm as BridgeForm,
        fromBalanceRelative,
      );
      console.log('bigADAMaxAmount', bigADAMaxAmount.toFixed());
      console.log('bridgeForm.fromAmount', bridgeForm.fromAmount.toFixed());
      setAmountTooHigh(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.gt(bigADAMaxAmount) &&
          !fromBalanceRelative.isLessThan(minADAAmountForBridge),
        format(bigADAMaxAmount.toFixed(2, BigNumber.ROUND_DOWN)),
      );
    }

    console.groupEnd();
  }

  function validateFromMilkomeda() {
    console.groupCollapsed('validation [ Milkomeda -> Cardano ]');

    const { bridgeForm, getMilkomedaGasTokenBalance, getMilkomedaTokenBalance } =
      useCardanoBridge();
    const cardanoWallet = useCardanoWallet();

    const gasTokenBalance = new BigNumber(getMilkomedaGasTokenBalance());

    // validate enough ada
    let lockedGas =
      BRIDGE_FEE_MILKOMEDA_IN_ADA.toNumber() +
      TX_FEE_MILKOMEDA_IN_ADA.toNumber() +
      MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA;
    if (!bridgeForm.fromToken.unwrapWETH().isETHToken()) {
      lockedGas = lockedGas + MILKOMEDA_APPROVE_FEE;
    }
    setIsEnoughAda(
      bridgeForm as BridgeForm,
      gasTokenBalance.gte(lockedGas),
      new BigNumber(lockedGas).toFixed(2, BigNumber.ROUND_UP),
    );
    console.log('ADA balance in Milkomeda : ', gasTokenBalance.toFixed());
    console.log(
      'need lock ADA in Milkomeda : ',
      new BigNumber(lockedGas).toFixed(6, BigNumber.ROUND_UP),
    );

    setWrongCardanoMode(bridgeForm as BridgeForm, cardanoWallet, !cardanoWallet.isNetworkSupported);

    if (bridgeForm.fromAmount.lte(0)) {
      console.groupEnd();

      return;
    }

    // validate balance exceed
    const fromBalanceRelative = getMilkomedaTokenBalance(bridgeForm.fromToken);
    console.log(`${bridgeForm.fromToken.symbol} balance in Milkomeda : `, fromBalanceRelative);
    setBalanceExceeded(bridgeForm as BridgeForm, bridgeForm.fromAmount.gt(fromBalanceRelative));

    const minAmountForBridge =
      BRIDGE_FEE_MILKOMEDA_IN_ADA.toNumber() + MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA;
    console.log('min bridge amount from Milkomeda : ', minAmountForBridge);

    // validate amount to low
    if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
      setAmountTooLow(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.isLessThan(minAmountForBridge),
        minAmountForBridge.toFixed(),
      );
    } else {
      const minNonADAAmount = 1 / 10 ** bridgeForm.fromToken.decimals;
      setAmountTooLow(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.isLessThan(minNonADAAmount),
        minNonADAAmount.toFixed(),
      );
    }

    // validate amount to high
    if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
      const bigADAMaxAmount = calculateMilkomedaMaxAmount(
        bridgeForm as BridgeForm,
        gasTokenBalance,
      );
      console.log('max ADA bridge amount : ', bigADAMaxAmount.toString());
      setAmountTooHigh(
        bridgeForm as BridgeForm,
        bridgeForm.fromAmount.gt(bigADAMaxAmount) &&
          !new BigNumber(fromBalanceRelative).isLessThan(minAmountForBridge),
        format(bigADAMaxAmount.toFixed(2, BigNumber.ROUND_DOWN)),
      );
    }

    console.groupEnd();
  }

  async function validateFormByNetworkAndToken(bridgeForm: BridgeForm) {
    console.groupCollapsed('[BRIDGE] validate form');

    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      await validateFromCardano();
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('validation isMilkomedaMainnetOrTestnet');
      validateFromMilkomeda();
    }

    console.groupEnd();
  }

  return {
    validateFromCardano,
    validateFromMilkomeda,
    validateFormByNetworkAndToken,
  };
}
