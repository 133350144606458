import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { BridgeResponse } from 'crypto-sdk';
import { getScanLink } from '@/sdk/utils';
import { dateNowInSeconds } from '@/helpers/utils';
import { BIG_ZERO } from '@/utils/bigNumber';
import { BridgeEventCommonPayload } from '@/store/modules/bridge/models/bridge-event';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import { INotificationStep } from '@/store/modules/notifications/models/notification.interface';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { useBridgeNotificationOptions } from '@/composables/bridge/useBridgeNotificationOptions';
import { useBridgeTransactions } from './useBridgeTransactions';
import { useNotifications } from '@/store/modules/notifications/useNotifications';

type Options = {
  notificationId: string;
  notificationStep: INotificationStep;
};

const BRIDGE_FROM_CARDANO_TO_MILKOMEDA_TOTAL_STEPS = 3;
const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;

export function useBridgeFromCardanoToMilkomeda() {
  const { updateTokenBalances } = useBalances();
  const { updateBalances: updateCardanoTokensBalances } = useCardanoTokens();
  const cardanoWallet = useCardanoWallet();
  const { bridgeFromCardano } = useBridgeTransactions();
  const { addNotification } = useNotifications();
  const {
    getBridgeInProgressNotificationOptions,
    getBridgeSuccessNotificationOptions,
    getBridgeErrorNotificationOptions,
  } = useBridgeNotificationOptions();

  async function processingDoBridge(
    bridgeForm: BridgeForm,
    bridgePayload: BridgeEventCommonPayload,
    milkomedaWalletAddress: string,
    opts: Options,
  ) {
    // STEP 1
    opts.notificationStep.current = STEP_1;

    addNotification(
      getBridgeInProgressNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
      }),
    );

    console.log(`waiting 'bridge' transaction`);
    const bridgeResponse = await bridgeFromCardano(bridgeForm, milkomedaWalletAddress);
    console.log('bridge response : ', bridgeResponse);

    return bridgeResponse;
  }

  async function processingTransactionFrom(
    bridgeResponse: BridgeResponse,
    bridgePayload: BridgeEventCommonPayload,
    opts: Options,
  ) {
    // STEP 1
    opts.notificationStep.current = STEP_1;

    const fromTx = bridgeResponse.from.tx;
    if (!fromTx) {
      console.warn(`[BRIDGE] 'from' transaction is undefined.`);
      throw Error('Can not get `from` transaction hash when bridge.');
    }

    const blockfrost = cardanoWallet.blockfrost;
    if (!blockfrost) {
      console.warn(`[BRIDGE] 'blockfrost' is undefined.`);
      throw Error('Can not get `blockfrost` when bridge.');
    }

    const cardanoNetworkId = cardanoWallet.networkId;
    if (cardanoNetworkId === null) {
      console.warn(`[BRIDGE] 'cardanoNetworkId' is undefined.`);
      throw Error('Can not get Cardano network id when bridge.');
    }

    addNotification(
      getBridgeInProgressNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
        explorerLink: getScanLink(fromTx.hash, 'transaction', bridgePayload.from.blockchain.id),
      }),
    );

    console.log(
      `waiting block for 'from' `,
      '( txHash : ',
      fromTx.hash,
      ' networkId : ',
      cardanoNetworkId,
      ' )',
    );
    const fromBlock = await blockfrost.getTxBlockHash(fromTx.hash, cardanoNetworkId);
    console.log(`has block for 'from' : `, fromBlock);

    addNotification(
      getBridgeSuccessNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
        explorerLink: getScanLink(fromTx.hash, 'transaction', bridgePayload.from.blockchain.id),
      }),
    );

    return bridgeResponse;
  }

  async function processingTransactionTo(
    bridgeResponse: BridgeResponse,
    bridgePayload: BridgeEventCommonPayload,
    opts: Options,
  ) {
    // STEP 2
    opts.notificationStep.current = STEP_2;

    console.log('=== processing STEP 2 ===');

    addNotification(
      getBridgeInProgressNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
      }),
    );

    console.log(`waiting 'to' transaction`);
    const toTx = await bridgeResponse.to.tx;
    console.log(`has 'to' transaction : `, toTx);

    addNotification(
      getBridgeSuccessNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
      }),
    );

    // STEP 3
    opts.notificationStep.current = STEP_3;

    if (!toTx) {
      console.warn(`[BRIDGE] 'to' transaction is undefined.`);
      throw Error('Can not get `to` transaction hash when bridge.');
    }

    console.log('=== processing STEP 3 ===');

    addNotification(
      getBridgeInProgressNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
        explorerLink: getScanLink(toTx.hash, 'transaction', bridgePayload.to.blockchain.id),
      }),
    );

    console.log(`waiting block for 'to' `, '( txHash : ', toTx.hash, ' )');
    const toBlock = await getInstance().web3.getSigner().provider.getTransaction(toTx.hash);
    console.log(`has block for 'to' : `, toBlock);

    addNotification(
      getBridgeSuccessNotificationOptions(bridgePayload, {
        id: opts.notificationId,
        step: {
          ...opts.notificationStep,
        },
        explorerLink: getScanLink(toTx.hash, 'transaction', bridgePayload.to.blockchain.id),
      }),
    );

    return bridgeResponse;
  }

  async function doBridgeFromCardanoToMilkomeda(bridgeForm: BridgeForm, account: string) {
    const bridgePayload: BridgeEventCommonPayload = {
      from: {
        blockchain: bridgeForm.fromBlockchain,
        token: bridgeForm.fromToken,
        amount: bridgeForm.fromAmount,
      },
      to: {
        blockchain: bridgeForm.toBlockchain,
        token: bridgeForm.toToken,
        amount: bridgeForm.toAmount ?? BIG_ZERO,
      },
    };

    const notificationId = `bridge_from_cardano_to_milkomeda_${dateNowInSeconds()}`;
    const notificationStep: INotificationStep = {
      chainId: undefined,
      current: STEP_1,
      total: BRIDGE_FROM_CARDANO_TO_MILKOMEDA_TOTAL_STEPS,
    };

    try {
      // STEP 1
      console.log('=== processing STEP 1 ===');
      const bridgeResponse = await processingDoBridge(bridgeForm, bridgePayload, account, {
        notificationId,
        notificationStep,
      });
      await processingTransactionFrom(bridgeResponse, bridgePayload, {
        notificationId,
        notificationStep,
      });

      // STEP 2, STEP 3
      await processingTransactionTo(bridgeResponse, bridgePayload, {
        notificationId,
        notificationStep,
      });

      await updateTokenBalances();
      await updateCardanoTokensBalances();
    } catch (error) {
      console.error(`[BRIDGE:CARDANO:TO:MILKOMEDA] Error : `, error);

      addNotification(
        getBridgeErrorNotificationOptions(bridgePayload, {
          id: notificationId,
          step: {
            ...notificationStep,
          },
        }),
      );

      throw error;
    }
  }

  return {
    doBridgeFromCardanoToMilkomeda,
  };
}
