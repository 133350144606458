import BigNumber from 'bignumber.js';
import { fromWei } from '@/sdk/utils';
import { BIG_ZERO, max, min } from '@/utils/bigNumber';
import {
  DEFAULT_BRIDGE_DECIMALS,
  MIN_ADA_BRIDGE,
} from '@/store/modules/bridge/constants/AMOUNT_LIMITS_FOR_BRIDGE';
import {
  BRIDGE_FEE_CARDANO_IN_ADA,
  KEEP_AMOUNT_INTO_CARDANO_IN_ADA,
  MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA,
  TX_FEE_CARDANO_WITH_MAGIC_NUMBER_IN_ADA,
  TX_FEE_MILKOMEDA_IN_ADA,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import {
  isCardanoMainnetOrTestnet,
  isMilkomedaMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import {
  removeBridgeError,
  parseBridgeErrors,
} from '@/store/modules/bridge/helpers/bridge-error-handler.helper';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import { Token } from '@/sdk/entities/token';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useAllWallets } from '@/store/modules/wallet/useAllWallets';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { TRANSLATION_KEYS } from '@/store/modules/bridge/constants/ERROR_TRANSLATION_KEYS';
import { useBridgeTransactions } from './useBridgeTransactions';
import {
  GAS_TOKENS_FEE,
  RECEIVE_GAS_TOKENS,
} from '@/store/modules/bridge/constants/RECEIVE_GAS_TOKENS';

export function useBridgeCalculations() {
  const { walletState } = useEVMWallet();
  const { isWalletInjected } = useAllWallets();
  const cardanoWallet = useCardanoWallet();
  const { bridgeFromCardano } = useBridgeTransactions();

  function normalizeAmountForBridge(amount: BigNumber) {
    return max(amount.toFixed(DEFAULT_BRIDGE_DECIMALS, BigNumber.ROUND_DOWN), BIG_ZERO);
  }

  async function estimateMaxBridgeAmountFromCardano(cardanoToken: Token) {
    let estimatedMaxAmountForBridge: BigNumber | undefined;

    const estimatedMaxAmountForBridgeInWei = (
      await cardanoWallet.wallet?.estimateMaxBridgeAmount(cardanoToken.address)
    )?.amount;

    if (estimatedMaxAmountForBridgeInWei) {
      estimatedMaxAmountForBridge = fromWei(
        estimatedMaxAmountForBridgeInWei,
        cardanoToken.decimals,
      );
    }

    return estimatedMaxAmountForBridge;
  }

  // MAX amount for Cardano
  async function calculateCardanoMaxAmount(
    bridgeForm: BridgeForm,
    balance: BigNumber,
  ): Promise<BigNumber> {
    console.groupCollapsed('[BRIDGE] calculate Cardano max amount');

    let balanceOrEstimatedMax = balance;
    console.log(`balance [ ${bridgeForm.fromToken.symbol} ] : `, balance.toString());

    // Estimate by wallet
    const estimatedMaxAmountForBridge = await estimateMaxBridgeAmountFromCardano(
      bridgeForm.fromToken,
    );

    // min [ balance | estimated max bridge ]
    if (estimatedMaxAmountForBridge) {
      console.log(
        `estimated max amount [ ${bridgeForm.fromToken.symbol} ] : `,
        estimatedMaxAmountForBridge.toString(),
      );

      balanceOrEstimatedMax = min(estimatedMaxAmountForBridge, balance);
    }
    console.log(
      `max amount [ ${bridgeForm.fromToken.symbol} ] (min) : `,
      balanceOrEstimatedMax.toString(),
    );

    // NOTE:
    // Should be: max_From = Bal - [keep] - [tx_fee],
    // BUT we use also a magic number: max_From = Bal - [keep] - [tx_fee] - [magic_number],
    const lockAmount = bridgeForm.fromToken.unwrapWETH().isETHToken()
      ? KEEP_AMOUNT_INTO_CARDANO_IN_ADA.plus(TX_FEE_CARDANO_WITH_MAGIC_NUMBER_IN_ADA)
      : BIG_ZERO;
    console.log(
      `need lock amount [ ${bridgeForm.fromToken.symbol} ] into Cardano : `,
      lockAmount?.toString(),
    );

    const maxAmountForBridge = balanceOrEstimatedMax.minus(lockAmount);
    console.log(
      `max amount [ ${bridgeForm.fromToken.symbol} ] after lock : `,
      maxAmountForBridge.toString(),
    );

    console.groupEnd();
    return normalizeAmountForBridge(maxAmountForBridge);
  }

  // MAX amount for Milkomeda
  function calculateMilkomedaMaxAmount(bridgeForm: BridgeForm, balance: BigNumber): BigNumber {
    console.groupCollapsed('[BRIDGE] calculate Milkomeda max amount');
    console.log(`max amount by balance [ ${bridgeForm.fromToken.symbol} ] : `, balance.toString());

    const isETHToken = bridgeForm.fromToken.unwrapWETH().isETHToken();

    const lockAmount = isETHToken ? TX_FEE_MILKOMEDA_IN_ADA : BIG_ZERO;
    console.log(
      `need lock amount [ ${bridgeForm.fromToken.symbol} ] into Milkomeda : `,
      lockAmount.toString(),
    );

    const maxAmountForBridge = balance.minus(lockAmount);
    console.log(
      `max amount by balance [ ${bridgeForm.fromToken.symbol} ] after lock : `,
      maxAmountForBridge.toString(),
    );

    console.groupEnd();
    return normalizeAmountForBridge(maxAmountForBridge);
  }

  function calculateMinGasBalanceInCardano(): number {
    return KEEP_AMOUNT_INTO_CARDANO_IN_ADA.plus(TX_FEE_CARDANO_WITH_MAGIC_NUMBER_IN_ADA)
      .plus(BRIDGE_FEE_CARDANO_IN_ADA)
      .plus(MIN_ADA_BRIDGE)
      .toNumber();
  }

  // MAX amount
  async function calculateMaxAmount(
    bridgeForm: BridgeForm,
    balance: BigNumber,
  ): Promise<BigNumber> {
    console.groupCollapsed('[BRIDGE] calculateMaxAmount');

    let maxAmount = balance;

    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      maxAmount = await calculateCardanoMaxAmount(bridgeForm, balance);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      maxAmount = calculateMilkomedaMaxAmount(bridgeForm, balance);
    }

    console.log(`max amount [ ${bridgeForm.fromToken.symbol} ]: `, maxAmount.toString());
    console.groupEnd();
    return maxAmount;
  }

  // Receive Gas Tokens and Gas Tokens Fee When Bridge from Cardano to Milkomeda
  function calculateReceiveGasTokensAndGasTokensFeeWhenFromCardano(bridgeFrom: BridgeForm): {
    receiveGasTokens: BigNumber;
    gasTokensFee: BigNumber;
  } {
    console.log('[BRIDGE] calculate receiveGasTokens and gasTokensFee [ Cardano -> Milkomeda ]');
    const isETHToken = bridgeFrom.fromToken.unwrapWETH().isETHToken();

    return {
      receiveGasTokens: isETHToken ? BIG_ZERO : new BigNumber(RECEIVE_GAS_TOKENS),
      gasTokensFee: isETHToken ? BIG_ZERO : new BigNumber(GAS_TOKENS_FEE),
    };
  }

  // Receive Gas Tokens and Gas Tokens Fee When Bridge from Milkomeda to Cardano
  function calculateReceiveGasTokensAndGasTokensFeeWhenFromMilkomeda(bridgeFrom: BridgeForm): {
    receiveGasTokens: BigNumber;
    gasTokensFee: BigNumber;
  } {
    console.log('[BRIDGE] calculate receiveGasTokens and gasTokensFee [ Milkomeda -> Cardano ]');
    const isETHToken = bridgeFrom.fromToken.unwrapWETH().isETHToken();

    return {
      receiveGasTokens: isETHToken ? BIG_ZERO : BigNumber(MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA),
      gasTokensFee: isETHToken ? BIG_ZERO : BigNumber(TX_FEE_MILKOMEDA_IN_ADA),
    };
  }

  // Receive Gas Tokens and Gas Tokens Fee
  function calculateReceiveGasTokensAndGasTokensFee(bridgeForm: BridgeForm): {
    receiveGasTokens: BigNumber;
    gasTokensFee: BigNumber;
  } {
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return calculateReceiveGasTokensAndGasTokensFeeWhenFromCardano(bridgeForm);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return calculateReceiveGasTokensAndGasTokensFeeWhenFromMilkomeda(bridgeForm);
    }

    return {
      receiveGasTokens: BIG_ZERO,
      gasTokensFee: BIG_ZERO,
    };
  }

  // amount To and Cardano tx fee when Cardano wallet is injected
  async function fetchBridgeAmountOut(
    bridgeForm: BridgeForm,
    account: string,
  ): Promise<{ toAmount: BigNumber; networkFee: BigNumber }> {
    const result = await bridgeFromCardano(bridgeForm, account, true);

    const feeTo = result.to.fee;
    const feeFrom = result.from.fee;

    const bridgeFeeRelative = fromWei(feeTo?.quantity ?? 0, feeTo?.decimals);
    const networkFeeRelative = fromWei(feeFrom?.quantity ?? 0, feeFrom?.decimals);
    const toAmount = bridgeForm.toToken.unwrapWETH().isETHToken()
      ? max(bridgeForm.fromAmount.minus(bridgeFeeRelative), 0)
      : bridgeForm.fromAmount;

    return {
      toAmount,
      networkFee: networkFeeRelative,
    };
  }

  // amount To and tx fee when Cardano wallet is not injected or bridge from Milkomeda
  function estimateBridgeAmountOutAndNetworkFee(bridgeForm: BridgeForm): {
    toAmount: BigNumber;
    networkFee: BigNumber;
  } {
    const networkFee = isCardanoMainnetOrTestnet[bridgeForm.fromBlockchain.name]
      ? TX_FEE_CARDANO_WITH_MAGIC_NUMBER_IN_ADA
      : TX_FEE_MILKOMEDA_IN_ADA;

    const bridgeFee =
      bridgeForm.fromBlockchain.name === bridgeForm.bridgeToken.blockchain1.name
        ? bridgeForm.bridgeToken.blockchain1Fee
        : bridgeForm.bridgeToken.blockchain2Fee;

    const isETHTokenToToken = bridgeForm.toToken.unwrapWETH().isETHToken();
    const toAmount = isETHTokenToToken
      ? max(bridgeForm.fromAmount.minus(bridgeFee), 0)
      : bridgeForm.fromAmount;

    return {
      toAmount,
      networkFee: new BigNumber(networkFee),
    };
  }

  // amount for Cardano and Milkomeda tx fee
  function calculateCardanoAmountToAndNetworkFee(bridgeForm: BridgeForm): {
    toAmount: BigNumber;
    networkFee: BigNumber;
  } {
    console.log('[BRIDGE] calculate amount TO and network fee [ Milkomeda -> Cardano ]');
    return estimateBridgeAmountOutAndNetworkFee(bridgeForm as BridgeForm);
  }

  // amount for Milkomeda and Cardano tx fee
  async function calculateMilkomedaAmountToAndNetworkFee(
    bridgeForm: BridgeForm,
  ): Promise<{ toAmount: BigNumber; networkFee: BigNumber }> {
    console.log('[BRIDGE] calculate amount TO and network fee [ Cardano -> Milkomeda ]');

    const EMPTY_RESULT = {
      toAmount: BIG_ZERO,
      networkFee: BIG_ZERO,
    };

    if (bridgeForm.fromAmount.isZero()) {
      return EMPTY_RESULT;
    }

    const walletConnected = isWalletInjected(bridgeForm.fromBlockchain.name).value;

    if (walletConnected) {
      bridgeForm.toAmount = null;
      bridgeForm.networkFee = null;

      try {
        const { toAmount, networkFee } = await fetchBridgeAmountOut(
          bridgeForm as BridgeForm,
          walletState.account ?? '',
        );

        bridgeForm.errors = removeBridgeError(TRANSLATION_KEYS.DEFAULT, bridgeForm.errors);

        return {
          toAmount,
          networkFee,
        };
      } catch (error) {
        console.error('Can not calculate amount TO (Milkomeda). Happen error : ', error);

        bridgeForm.toAmount = BIG_ZERO;
        bridgeForm.networkFee = BIG_ZERO;

        const errorMsg = error.toString().replace('Wallet error: ', '');
        bridgeForm.errors = parseBridgeErrors(errorMsg, bridgeForm.errors);
      }
    }

    return estimateBridgeAmountOutAndNetworkFee(bridgeForm as BridgeForm);
  }

  // AMOUNT TO
  async function calculateAmountToAndNetworkFee(bridgeForm: BridgeForm) {
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return await calculateMilkomedaAmountToAndNetworkFee(bridgeForm);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      return calculateCardanoAmountToAndNetworkFee(bridgeForm);
    }

    return {
      toAmount: BIG_ZERO,
      networkFee: BIG_ZERO,
    };
  }

  return {
    calculateMaxAmount,
    calculateCardanoMaxAmount,
    calculateMilkomedaMaxAmount,
    calculateMinGasBalanceInCardano,
    calculateAmountToAndNetworkFee,
    calculateReceiveGasTokensAndGasTokensFee,
  };
}
